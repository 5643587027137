<template>
  <div class="main">
    <Header></Header>
    <div style="display:flex;flex:1;">
      <swiper :options="swiperOption" ref="mySwiper" :style="'height:'+swiperHeight+'px;width:20%;color:white;'">
        <swiper-slide v-for=" (item ,index) in deviceList" :key="index">
          <dv-border-box-10>
            <div style="display:flex; justify-content:center; align-items:center;height:100%;">
              <dv-decoration-11 style="width:90%;height:50%;">{{item.remark?item.remark:item.serialNumber}}</dv-decoration-11>
            </div>
          </dv-border-box-10>
        </swiper-slide>
      </swiper>
      <div style="display:flex;flex:1;flex-direction: column;">
        <dv-border-box-8 style="height:15%;">
          <div style="display:flex;flex-direction:column;height:100%;padding:2px;">
            <dv-scroll-board :config="config_device_record" />
          </div>
        </dv-border-box-8>
        <div style="display:flex;flex:1">
          <!-- <dv-charts ref="device_chart" :option="chart_option" /> -->
          <div id="highChartForWXCW" style="flex:1;"></div>
          <div style="width:20%;">
            <dv-border-box-12>
              <div style="height:100%;display:flex;flex-direction:column;align-items:center;">
                <dv-decoration-1 style="width:100%;height:5%;"></dv-decoration-1>
                <div style="flex:1;display:flex;flex-direction:column;justify-content:center;">
                  <div style="color:white;font-size:30px;text-align:center;">温差报警</div>
                  <dv-digital-flop :config="wenChaBaoJing" style="width:100%;height:50px;" />
                </div>
                <dv-decoration-2 style="height:5%;width:95%;" />
                <div style="flex:1;display:flex;flex-direction:column;justify-content:center;">
                  <div style="color:white;font-size:30px;text-align:center;">上下限报警</div>
                  <dv-digital-flop :config="shangXiaXianBaoJing" style="width:100%;height:50px;" />
                </div>
                <dv-decoration-1 style="width:100%;height:5%;"></dv-decoration-1>
              </div>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import dataV from "@jiaminghi/data-view";
import Header from "./Header.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import HighCharts from "highcharts";
import Moment from "moment";

Vue.use(dataV);
Vue.use(VueAwesomeSwiper);
export default {
  components: { Header },
  data() {
    let that = this;
    return {
      //从服务器获取到的数据加载到HighCharts中
      dataListAll: [],
      //当前公司下所有设备的列表
      deviceList: [],
      config_device_record: {
        data: [],
      },
      wenChaBaoJing: { number: [0], content: "{nt}条" },
      shangXiaXianBaoJing: { number: [0], content: "{nt}条" },
      config_device_count: {
        data: [
          {
            name: "周口",
            value: 55,
          },
          {
            name: "南阳",
            value: 120,
          },
          {
            name: "西峡",
            value: 78,
          },
          {
            name: "驻马店",
            value: 66,
          },
          {
            name: "新乡",
            value: 80,
          },
        ],
      },
      swiperHeight: 0,
      swiperOption: {
        slidesPerView: 5,
        direction: "vertical",
        autoplay: {
          delay: 30000,
          disableOnInteraction: false,
        },
        on: {
          slideChange() {
            that.getChartDataAndLoad(
              that.deviceList[this.activeIndex % that.deviceList.length]
                .serialNumber
            );
          },
        },
        loop: true,
        // height: 500,
        //width: 300,
      },
    };
  },
  methods: {
    getChartDataAndLoad(serialNumber) {
      let that = this;
      //获取过去三天的记录
      that.axios
        .post("WXCW01/GetList", {
          serialNumber: serialNumber,
          BeginDate: Moment().add(-3, "day"),
          EndDate: Moment(),
        })
        .then(function (response) {
          that.dataListAll = response.data.data;
          that.loadChart();
          that.load_config_device_record();
        });
      //获取报警

      that.axios
        .post("WXCW_BigScreen/GetBaoJing", { OnlyText: serialNumber })
        .then(function (response) {
          let arr = response.data.data.split(",");
          that.wenChaBaoJing = {
            number: [parseInt(arr[0])],
            content: "{nt}条",
          };
          that.shangXiaXianBaoJing = {
            number: [parseInt(arr[1])],
            content: "{nt}条",
          };
        });
    },
    load_config_device_record() {
      let that = this;
      let tmp = {
        header: ["时间", "T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8"],
        data: [],
        columnWidth: [150],
        align: [
          "left",
          "right",
          "right",
          "right",
          "right",
          "right",
          "right",
          "right",
          "right",
        ],
        rowNum: 1,
      };
      for (var i = 0; i < that.dataListAll.length && i < 1; i++) {
        tmp.data.push([
          that.dataListAll[i].dataTime,
          that.dataListAll[i].t1 == 2500
            ? "-"
            : (that.dataListAll[i].t1 / 10.0).toString() + "℃",
          that.dataListAll[i].t2 == 2500
            ? "-"
            : (that.dataListAll[i].t2 / 10.0).toString() + "℃",
          that.dataListAll[i].t3 == 2500
            ? "-"
            : (that.dataListAll[i].t3 / 10.0).toString() + "℃",
          that.dataListAll[i].t4 == 2500
            ? "-"
            : (that.dataListAll[i].t4 / 10.0).toString() + "℃",
          that.dataListAll[i].t5 == 2500
            ? "-"
            : (that.dataListAll[i].t5 / 10.0).toString() + "℃",
          that.dataListAll[i].t6 == 2500
            ? "-"
            : (that.dataListAll[i].t6 / 10.0).toString() + "℃",
          that.dataListAll[i].t7 == 2500
            ? "-"
            : (that.dataListAll[i].t7 / 10.0).toString() + "℃",
          that.dataListAll[i].t8 == 2500
            ? "-"
            : (that.dataListAll[i].t8 / 10.0).toString() + "℃",
        ]);
      }
      that.config_device_record = tmp;
    },
    loadChart() {
      let that = this;
      var mapData = [];
      mapData.push({ name: "T1", data: [] });
      mapData.push({ name: "T2", data: [] });
      mapData.push({ name: "T3", data: [] });
      mapData.push({ name: "T4", data: [] });
      mapData.push({ name: "T5", data: [] });
      mapData.push({ name: "T6", data: [] });
      mapData.push({ name: "T7", data: [] });
      mapData.push({ name: "T8", data: [] });
      for (var i = that.dataListAll.length - 1; i >= 0; i--) {
        if (that.dataListAll[i].t1 != 2500)
          mapData[0].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t1 / 10.0,
          ]);
        if (that.dataListAll[i].t2 != 2500)
          mapData[1].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t2 / 10.0,
          ]);
        if (that.dataListAll[i].t3 != 2500)
          mapData[2].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t3 / 10.0,
          ]);
        if (that.dataListAll[i].t4 != 2500)
          mapData[3].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t4 / 10.0,
          ]);
        if (that.dataListAll[i].t5 != 2500)
          mapData[4].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t5 / 10.0,
          ]);
        if (that.dataListAll[i].t6 != 2500)
          mapData[5].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t6 / 10.0,
          ]);
        if (that.dataListAll[i].t7 != 2500)
          mapData[6].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t7 / 10.0,
          ]);
        if (that.dataListAll[i].t8 != 2500)
          mapData[7].data.push([
            Moment(that.dataListAll[i].dataTime).valueOf(),
            that.dataListAll[i].t8 / 10.0,
          ]);
      }

      HighCharts.chart("highChartForWXCW", {
        tooltip: {
          formatter: function () {
            return (
              this.series.name +
              "<br />温度：" +
              this.y +
              "℃<br />时间：" +
              Moment(this.x).format("YYYY-MM-DD HH:mm:ss")
            );
          },
        },
        chart: { backgroundColor: "rgba(0,0,0,0)" },
        title: { text: "温度曲线图" },
        credits: { enabled: false },
        exporting: { enabled: false },
        global: { useUTC: false },
        yAxis: { title: { text: "温度" } },
        xAxis: { type: "datetime" },
        series: mapData,
      });
    },
  },

  mounted() {
    let that = this;
    //获取同一个公司下的所有无线测温仪设备
    that.axios
      .post("WXCW_BigScreen/GetDeviceByCompanyName", {
        OnlyText: that.$route.query["c"],
      })
      .then(function (response) {
        that.deviceList = response.data.data;
        if (that.deviceList.length > 0) {
          that.getChartDataAndLoad(that.deviceList[0].serialNumber);
          if (that.deviceList.length == 1) {
            for (var a = 0; a < 4; a++) {
              that.deviceList.push(that.deviceList[a]);
            }
          } else if (that.deviceList.length == 2) {
            for (var b = 0; b < 4; b++) {
              that.deviceList.push(that.deviceList[b]);
            }
          } else if (that.deviceList.length == 3) {
            for (var c = 0; c < 3; c++) {
              that.deviceList.push(that.deviceList[c]);
            }
          } else if (that.deviceList.length == 4) {
            for (var d = 0; d < 4; d++) {
              that.deviceList.push(that.deviceList[d]);
            }
          }
        }
      });

    // that.chart_option.series[0].data[6] = 8000;
    that.swiperHeight = that.$parent.$el.offsetHeight - 80;
  },
};
</script>

<style scoped>
.main {
  background-image: url("/images/bigscreen/bg1.png");
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.main2 {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.main3 {
  height: 400px;
  display: flex;
}
</style>